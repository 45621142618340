/* .wise9ja-disp {
    background: #F2F2F2;
} */


.homepage.home-display .content-display-content {
    height: calc(100% - 150px);
    height: 100%;
    padding-top: 170px;
}

.homepage .content-display-content {
    padding-bottom: 40%;
}

.extra-data-message {
    background: #083F75;
    border-radius: 12px;
    position: relative;
    overflow: hidden;
    height: 10rem;
}

.extra-data-message.red {
    background: #A9411D;
}

.extra-data-message h3 {
    color: #fff;
    padding-bottom: 10px;
    font-size: 1.4rem;
}

.extra-data-message p {
    color: #F3F6F5;
    margin-bottom: 0px;
    padding-bottom: 0px;
    line-height: 1.6;
    font-size: 1.4rem;
}

.extra-data-message .extra-div {
    position: absolute;
    width: 30%;
    height: 320%;
    background: #1C4F80;
    top: -60%;
    transform: rotate(-70deg);
}

.extra-data-message.red .extra-div {
    background: #BA522D;
}

.extra-data-message .extra-div._2 {
    width: 30%;
    height: 170%;
    top: 0%;
    left: 35%;
    transform: unset;
}

.extra-data-message .extra-div._3 {
    position: absolute;
    width: 30%;
    height: 310%;
    top: -60%;
    right: 0px;
    transform: rotate(70deg);
}

.extra-data-message .extra-data-content {
    position: absolute;
    z-index: 2;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    flex-direction: column;
    padding: 0px 15px;
}


.content-summary-box {
    border: 1px solid #0E61D9;
    padding: 15px 15px;
    background: #0E61D9;
    border-radius: 4px;
}

.content-summary-box._2 {
    background: #7F1DE6;
    border: 1px solid #7F1DE6;
}

.content-summary-box._3 {
    background: #222222;
    border: 1px solid #222222;
}

.content-summary-box._4 {
    background: #6A3ADE;
    border: 1px solid #6A3ADE;
}

.content-summary-box h4 {
    font-size: 1.8rem;
    color: #fff;
    margin-bottom: 5px;
}

.content-summary-box p {
    margin-bottom: 0px;
    color: rgb(255, 255, 255, .7);
}

.wise9ja-disp .wise9ja-card-detail .content-summary-box p {
    text-align: left;
}

.avatar-cover {
    width: 50px;
    height: 50px;
    background: #1A1A1A;
    border-radius: 50%;
}

.flex-block {
    display: flex;
    align-items: center;
    grid-gap: 10px;
}

.wise9ja-disp .greeting-display {
    padding: 20px 0px;
}

.wise9ja-disp .grid-flex {
    align-items: center;
}

.wise9ja-disp .wise9ja-card-detail {
    background: #E4E4E4;
    padding: 5% 0px;
    padding-top: 1.5%;
}

.wise9ja-disp .wise9ja-inside-card {
    background: #1A1A1A;
    width: 100%;
    border-radius: 16px;
    margin-bottom: 20px;
    padding: 20px;
}

.wise9ja-disp .wise9ja-inside-card svg {
    stroke: #fff;
    height: 1.2rem;
}

.wise9ja-disp .wise9ja-inside-card button.btn-white {
    border-radius: 50px;
    font-size: 1.2rem;
    padding: 10px 25px;

}

.wise9ja-disp .wise9ja-inside-card .grid-flex {
    align-items: center;
}

.wise9ja-disp .wise9ja-inside-card h5,
.wise9ja-disp .wise9ja-inside-card a {
    font-size: 1.4rem;
    color: #fff;
    /* text-decoration: none; */
}

.wise9ja-disp .wise9ja-icon-cover {
    background: #D0D0D0;
    height: 50px;
    width: 50px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0px auto;
    margin-bottom: 5px;
}

.wise9ja-disp .wise9ja-icon-cover svg {
    height: 1.8rem;
    stroke: #000;
}

.wise9ja-disp .wise9ja-icon-cover ion-icon {
    font-size: 2rem;
}

.wise9ja-amount-box h3 {
    color: #fff;
    font-size: 3rem;
}

.wise9ja-disp .wise9ja-card-detail a {
    text-decoration: none;
}

.wise9ja-disp .wise9ja-card-detail p {
    text-align: center;
    font-family: 'Gilroy Bold';
    font-size: 1.2rem;
}

.wise9ja-disp .wise9ja-card-detail .grid-3 p {
    color: #000;
}

.cart-content.display-main-content {
    padding-bottom: 50%;
}

.price-listing .grid-flex h4 {
    font-size: 1.4rem;
}


.notifications-box .current-history-display {
    display: grid;
    grid-template-columns: 1fr 6fr;
}

.notifications-box .current-history-display p {
    font-size: 1.1rem;
    line-height: 1.8;
    font-family: 'Gilroy Medium';
}

.notifications-box ul {
    margin-bottom: 0px;
    display: flex;
    grid-gap: 5px;
}

.notifications-box ul li {
    list-style: none;
    font-size: 1.1rem;
    font-family: 'Gilroy Medium';
}

.notifications-box .current-history-display h3 {
    margin-bottom: 5px;
}

.notifications-box {
    background: #fff;
    padding: 10px;
    border-radius: 12px;
    margin-bottom: 20px;
}

.gray-page {
    background: #EEEEEE;
}


.center-modal-content {
    text-align: center;
}

.modal-content h4 {
    font-size: 1.8rem;
    margin-bottom: 10px;
}

.modal-content p {
    font-size: 1.6rem;
    line-height: 1.8;
    margin-bottom: 10px;
    font-family: 'Gilroy Regular';
}

.modal-content svg {
    height: 5rem;
    margin-bottom: 20px;
}

.ant-drawer-content,
.ant-drawer-content-wrapper {
    border-radius: 20px 20px 0px 0px;
}

.page-intro {
    text-align: center;
}

.page-intro .page-intro-box {
    width: 80px;
    height: 80px;
    border-radius: 50%;
    background: #D0D0D0;
    margin: 0px auto;
    margin-bottom: 10px;
}

.page-intro h4 {
    font-size: 1.8rem;
    margin-bottom: 5px;
}

.page-intro p {
    font-size: 1.6rem;
    margin: 0px auto;
    display: block;
    width: 80%;
    line-height: 1.6;
}

.homepage .wise9ja-card-detail a.btn.btn-base,
.homepage .wise9ja-card-detail .btn.btn-base {
    width: 100%;
    border-radius: 4px;
    padding: 15px;
    display: block;
}

.history-data-detail .current-history-display.first-history-disp {
    margin-top: 20px;
}


.history-data-detail .current-history-display {
    display: grid;
    grid-template-columns: 1fr 3fr 1.5fr;
    align-items: center;
}

.history-data-detail .current-history-display.first-history-disp {
    margin-top: 20px;
}

.history-data-detail .current-history-display ul {
    margin-bottom: 0px;
    display: flex;
    grid-gap: 5px;
}

.history-data-detail .current-history-display ul li {
    list-style: none;
    font-size: 1.1rem;
    font-family: 'Gilroy Medium';
}

.history-data-detail .current-history-display h3 {
    font-size: 1.3rem;
    margin-bottom: 10px;
    /* font-family: 'Gilroy Medium'; */
}

.history-data-detail .current-history-display h3.history-amount {
    font-family: 'Gilroy Bold';
    text-align: right;
}

.history-data-detail .current-history-display p.status {
    font-size: 1.3rem;
}

.history-data-detail .current-history-display p.status.success {
    font-size: 1.3rem;
    color: #096D55;
    font-family: 'Gilroy Medium';
    text-align: right;
}

.history-data-detail .disp-category {
    height: 45px;
    width: 45px;
    border-radius: 50%;
    background: #808080;
}

.cart-box {
    background: var(--base-color);
    display: flex;
    padding: 10px 15px;
    border-radius: 50px;
    align-items: center;
    grid-gap: 10px;
}

.cart-box svg {
    stroke: #fff;
    height: 1.6rem;
}

.top-welcome-address svg.menu-icon {
    height: 2.5rem;
    transform: rotate(-30deg);
}

.cart-box h4 {
    font-size: 1.4rem;
    color: #fff;
    margin-bottom: 0px;
}


.top-welcome-address h5 {
    font-size: 1.4rem;
}

.top-welcome-address p {
    font-size: 1.2rem;
    margin-bottom: 5px;
}

.top-welcome-address svg {
    height: 2rem;
    width: auto;
}

.top-welcome-address.grid-flex {
    align-items: center;
}

.currency {
    font-size: 1.2rem;
}

p.ant-upload-text {
    font-size: 1.2rem !important;
}

.ant-upload-drag-icon svg {
    width: auto;
    height: 2rem;
}

.ant-upload-drag-icon {
    margin-bottom: 5px !important;
    margin-top: 20px;
}