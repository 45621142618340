/* .auth-banner {
    background: url('../images/illustrations/auth_1.jpg');
    background-size: cover;
    background-position: center;
    height: 30rem;
}

label {
    font-size: 1.3rem;
    font-family: 'Gilroy Medium';
} */

/* form */
/* .form_group {
    margin-bottom: 20px;
    display: block;
}

.ant-drawer-title {
    font-family: 'Gilroy Bold';
}

input,
textarea,
.ant-select-selector {
    border-radius: 12px !important;
    border-width: 1.5px !important;
    font-size: 1.2rem !important;
}

.signin-page form button {
    width: 100%;
    display: block;
    background: var(--base-color);
    border: 1px solid var(--base-color);
    padding: 12px 25px;
    text-align: center;
    font-size: 1.4rem;
    line-height: 1.8;
    color: #fff;
    border-radius: 4px;
    margin-bottom: 30px;
}


.signin-page .grid-flex {
    margin-bottom: 20px;
}

.signin-page a {
    font-size: 1.4rem;
    line-height: 1.8;
}

.signin-page h3 {
    font-size: 1.8rem;
    margin-bottom: 5px;
    padding-top: 20px;
}

.signin-page p {
    font-size: 1.4rem;
    margin-bottom: 20px;
    line-height: 1.6;
    font-family: 'Gilroy Medium';
}

.signin-page .btn-base {
    width: 100%;
}

.welcome-buttons .welcome-buttons-content {
    display: block;
    padding: 0 10px;
}

.auth-btn-blank, .welcome-buttons a {
    border: 1px solid grey;
    border-radius: 4px;
    display: block;
    font-size: 1.4rem;
    padding: 12px 25px;
    text-align: center;
    text-decoration: none;
    width: 100%;
} */


.signin-page {
    height: 100%;
    overflow: scroll;
    background: #F7F7F9;
    position: relative;
}

.auth-banner {
    background: url('../images/illustrations/auth_1.jpg');
    background-size: cover;
    background-position: center;
    height: 30rem;
    height: 30dvh;
}

.signin-page .auth-content {
    border-top: 1px solid #E1E1E5;
}

.signin-page .top-bar {
    background: #fff;
}

.signin-page .auth-content input,
.signin-page .auth-content textarea,
.signin-page .auth-content .ant-select-selector,
.signin-page .ant-input-affix-wrapper.ant-input-password {
    background: #EDEDF5;
    background: transparent !important;
    border: 2px solid #E1E1E5 !important;
    border-radius: 12px !important;
}

.signin-page .ant-input-affix-wrapper.ant-input-password input {
    background: transparent !important;
    border: none !important;
}

.signin-page .auth-content label {
    margin-bottom: 5px;
    display: block;
}

.form-flex-2 {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 10px;
}

/* label {
    font-size: 1.3rem;
    font-family: 'Gilroy Medium';
} */

input,
textarea,
.ant-select-selector {
    border-radius: 12px !important;
    border-width: 1.5px !important;
    font-size: 1.2rem !important;
}

label {
    font-size: 1.2rem;
    font-family: 'Gilroy Medium';
}

/* form */
.form_group {
    margin-bottom: 20px;
    display: block;
}

.ant-drawer-title {
    font-family: 'Gilroy Bold';
}

.signin-page form button {
    width: 100%;
    display: block;
    background: var(--base-color);
    border: 1px solid var(--base-color);
    padding: 12px 25px;
    text-align: center;
    font-size: 1.4rem;
    line-height: 1.8;
    color: #fff;
    border-radius: 4px;
    /* margin-bottom: 30px; */
}

.signin-page form button.auth-btn-blank {
    background: transparent;
    border: 1px solid #808080;
    color: var(--base-color);
}

.form-error {
    font-size: 1.3rem;
    color: #B60001;
}


.signin-page .grid-flex {
    padding: 15px 0px;
}

.signin-page a {
    font-size: 1.4rem;
    line-height: 1.8;
    text-decoration: none;
    /* color: #000; */
    margin-bottom: 15px;
    display: block;
}

.signin-page h3 {
    font-size: 1.8rem;
    margin-bottom: 30px;
    padding-top: 20px;
}

.signin-page.verification_page h3.auth-sect-title {
    margin-bottom: 5px;
}

.signin-page.verification_page .contain.auth-content p {
    margin-bottom: 40px;
}

.signin-page.verification_page .contain.auth-content p.form-error {
    margin-bottom: 5px;
}

.display-main-content {
    padding-top: 7rem;
}

.sec-page-title {
    font-size: 1.8rem;
    margin-bottom: 10px;
    margin-top: 10px;
}

.top-selection-bar {
    display: flex;
    grid-gap: 10px;
    align-items: center;
}

.top-selection-bar .select-bar {
    border: 3px solid #fff;
    border: none;
    padding: 4px;
    border-radius: 50px;
}

.top-selection-bar .select-bar .active-inner-text {
    padding: 10px 15px;
    border-radius: 50px;
    font-size: 1.2rem;
    background: #808080;
}

.top-selection-bar .select-bar h4 {
    color: #fff;
}

.container {
    padding: 0% 3%;
}

.top-selection-bar .select-bar.active-bar {
    border: 3px solid var(--base-color);
}

.small-page-title {
    grid-gap: 5px;
    position: fixed;
    height: 6rem;
    top: 0px;
    width: 100%;
    background: #fff;
    z-index: 10;
    border-bottom: 2px solid var(--gray-color);
    display: flex;
    align-items: center;
    padding: 0px 1.5%;
}

.signin-page p.form-error {
    margin-bottom: 0px;
    font-size: 1.2rem;
}

.small-page-title .grid-flex {
    justify-content: space-between;
    align-items: center;
    width: 100%;
}

.contain.auth-content .mt_3 {
    margin-top: 6.5rem;
}

.signin-page p {
    font-size: 1.4rem;
    margin-bottom: 20px;
    line-height: 1.6;
    /* font-family: 'Gilroy Medium'; */
}

.welcome-buttons .welcome-buttons-content {
    display: block;
    padding: 0 10px;
}

.auth-btn-blank,
.welcome-buttons a {
    border: 1px solid grey;
    border-radius: 4px;
    display: block;
    font-size: 1.4rem;
    padding: 12px 25px;
    text-align: center;
    text-decoration: none;
    width: 100%;
}

.ant-drawer-header {
    display: none !important;
}

.ant-drawer-content-wrapper {
    height: max-content !important;
}

.ant-drawer-content,
.ant-drawer-content-wrapper {
    border-radius: 24px 24px 0px 0px;
}

.center-div {
    text-align: center;
}

.center-div p {
    font-size: 1.6rem;
    line-height: 1.8;
    margin-bottom: 20px;
}

.center-div h5 {
    font-size: 1.8rem;
    margin-bottom: 5px;
}

.auth-box button {
    padding: 15px 25px;
    width: 100%;
    border-radius: 4px;
}

.auth-icon-box {
    background: #808080;
    height: 130px;
    width: 130px;
    border-radius: 50%;
    margin: 0px auto;
    display: block;
    margin-bottom: 30px;
}

.modal-header .grid-flex svg {
    height: 2rem;
}

.modal-header .grid-flex {
    align-items: center;
}