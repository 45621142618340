.page-content {
    height: 100dvh;
    overflow: hidden;
    overflow: scroll;
    position: relative;
}

.ant-spin-nested-loading,
.ant-spin-container,
.display-page {
    height: 100%;
}

.page-content .base-message {
    padding-bottom: 20px;
    background: unset;
}

.base-message {
    position: fixed;
    background: inherit;
    bottom: 0px;
    left: 0px;
    width: 100%;
    height: max-content;
    padding: 10px 1.5%;
}

.base-message button,
.base-message a {
    width: 100%;
    display: block;
}

.base-message .btn-blank {
    border: 1px solid #dddcdc;
    background: transparent;
    color: #dddcdc;
    padding: 15px 25px;
    border-radius: 50px;
}

.welcome-data .base-message a.btn {
    border-radius: 50px;
}

/* .base-message button.btn {
    border-radius: 50px;
} */

.logo {
    height: 3rem;
    width: auto;
}

.center-message {
    text-align: center;
}

.welcome-data {
    padding-top: 5%;
    background: #003032;
    height: 100dvh;
}

.welcome-data .welcome-img-cover {
    /* height: 60dvh; */
}

.welcome-data .welcome-img-cover img {
    width: 85%;
    height: 100%;
    margin: 5% auto;
    display: block;
    display: none;
}

.welcome-data .welcome-cover {
    width: 100%;
    height: 50dvh;
    margin: 10% 0px;
    border-radius: 50%;
    background: #dddcdc;
}

.welcome-data p {
    font-size: 1.3rem;
    line-height: 1.8;
    color: #dddcdc;
}

.welcome-data h3 {
    font-size: 1.6rem;
    margin-bottom: 5px;
    color: #fff;
}

.welcome-data .btn {
    padding: 18px;
}