:root {
    --base-color: #003032;
    --gray-color: #E8E8E8;
}

* {
    margin: 0px;
    padding: 0px;
    box-sizing: border-box;
}

@font-face {
    font-family: 'Gilroy Extra-Bold';
    src: url('../fonts/Gilroy-FREE/Gilroy-FREE/Gilroy-ExtraBold.otf');
}

@font-face {
    font-family: 'Gilroy Bold';
    src: url('../fonts/Gilroy-FREE/Gilroy-FREE/Gilroy-Bold.ttf');
}

@font-face {
    font-family: 'Gilroy Medium';
    src: url('../fonts/Gilroy-FREE/Gilroy-FREE/Gilroy-Medium.ttf');
}

@font-face {
    font-family: 'Gilroy Light';
    src: url('../fonts/Gilroy-FREE/Gilroy-FREE/Gilroy-Light.otf');
}

* {
    font-family: 'Gilroy Medium';
}

body,
html {
    font-size: 10px;
    margin: 0px;
    padding: 0px;
    box-sizing: border-box;
    /* background: #F7F9FA; */
    font-family: 'Gilroy Medium';
}

body {
    position: relative;
    height: 100vh;
    overflow: hidden;
}

a,
button,
h1,
h2,
h3,
h4,
h5,
h6 {
    font-family: 'Gilroy Bold';
    color: var(--base-color);
    font-weight: 400;
    /* font-family: 'IBM Plex Sans', sans-serif; */
}

.nav {
    background: #fff;
    width: 100%;
    height: 60px;
    border-bottom: 1px solid #E5E7EB;
}

.contain {
    padding: 0px 2%;
}

.app-dashboard {
    display: flex;
    flex-direction: column;
    height: 100vh;
    max-height: 100vh;
    overflow: hidden;
}

.app-dashboard-cover {
    height: 100%;
    display: grid;
    grid-template-columns: 20% 80%;
    height: calc(100% - 60px);
    min-height: calc(100% - 60px);
}

.app-dashboard-cover .app-dashboard-nav {
    width: 100%;
    border-right: 1px solid #E5E7EB;
    background: #fff;
}

.app-dashboard-cover .app-dashboard-nav ul li {
    padding: 20px 30px;
}

.app-dashboard-cover .app-dashboard-nav ul li a {
    font-size: 1.4rem;
    text-decoration: unset;
    color: #111;
}

.app-dashboard-cover .app-dashboard-nav .sidenav {
    height: 100%;
    position: relative;
}

.app-dashboard-cover .app-dashboard-nav .sidenav ul li a {
    display: flex;
    align-items: center;
}

.app-dashboard-cover .app-dashboard-nav .sidenav ul li svg {
    height: 1.8rem;
    width: auto;
    margin-right: 10px;
}

.app-dashboard-cover .app-dashboard-nav .sidenav ul li svg.edit {
    height: 1.6rem;
    width: auto;
    margin-right: 10px;
}

.app-dashboard-cover .app-dashboard-nav .sidenav .last-links {
    position: absolute;
    bottom: 0%;
}

.app-dashboard-cover .app-dashboard-main {
    width: 100%;
    margin-left: 20%;
    margin-left: 0px;
    padding: 30px 30px;
    display: flex;
    flex-direction: column;
    position: relative;
    overflow: scroll;
    overflow-x: hidden;
}

.app-dashboard-cover .app-dashboard-summary {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-gap: 20px;
}

.app-dashboard-cover .dashboard-title {
    font-size: 1.8rem;
    margin-bottom: 10px;
}

p.priority-display {
    display: flex;
    align-items: center;
    grid-gap: 5px;
    justify-content: center;
    margin-bottom: 3px;
    font-size: 1.4rem;
}

p span {
    height: 10px;
    width: 10px;
    border-radius: 50%;
    display: block;
}

span.green {
    background: #10AA58;
    border: 1px solid #10AA58;
}

span.yellow {
    background: #FCB500;
    border: 1px solid #FCB500;
}

span.red {
    background: #FF0000;
    border: 1px solid #FF0000;
}

.buyer-details {
    display: flex;
}

.buyer-details ul li {
    display: flex;
}

.buyer-details ul li p {
    font-size: 1.4rem;
    margin-bottom: 0px;
}

.buyer-details ul li h5 {
    font-size: 1.4rem;
    margin-bottom: 0px;
}

.ant-table-cell {
    font-family: 'Gilroy Medium';
    font-size: 1.3rem;
}

.ant-table-cell p.btn,
.ant-table-cell button,
.ant-table-cell a {
    font-size: 1.3rem !important;
    font-family: 'Gilroy Medium';
}

thead tr th {
    /* font-weight: unset !important; */
    font-family: 'Gilroy Bold' !important;
    font-size: 1.4rem !important;
}

/* .app-dashboard-cover .app-dashboard-summary .summary-box */

/* button */
.welcome-buttons {
    bottom: 20px;
    left: 0;
    position: absolute;
    width: 100%;
}

.btn-base {
    background: var(--base-color);
    border: 1px solid var(--base-color);
    border-radius: 4px;
    color: #fff;
    padding: 15px 25px;
}

.btn {
    font-size: 1.4rem;
    color: #fff;
    padding: 5px 15px;
    width: max-content;
    border-radius: 4px;
    line-height: 1.2;
    text-align: center;
    text-decoration: none;
    font-family: 'Gilroy Bold';
}

.btn-round {
    border-radius: 50px;
}

.btn-full {
    width: 100%;
}

.btn-red {
    background: #FF0000;
    border: 1px solid #FF0000;
}

.btn-green {
    background: #10AA58;
    border: 1px solid #10AA58;
}

.btn-white {
    color: #003032;
    background: #fff;
    border: 1px solid #fff;
}

.btn-border-green {
    background: transparent;
    border: 1px solid #10AA58;
}

.btn-yellow {
    background: #FCB500;
    border: 1px solid #FCB500;
}

.btn-black {
    background: #111;
    border: 1px solid #111;
    border-radius: 50px;
}

.btn-blank {
    border: 1px solid #dddcdc;
    /* background: transparent; */
    /* border: none; */
    color: #111;
    padding: 15px 25px;
    /* padding: 0px; */
}

/* layout */
.mt_10 {
    margin-top: 10%;
}

.mt_15 {
    margin-top: 15%;
}

.mt_7 {
    margin-top: 7%;
}

.mt_5 {
    margin-top: 5%;
}

.mt_10 {
    margin-top: 10%;
}

.mt_3 {
    margin-top: 3%;
}

.mt_4 {
    margin-top: 4%;
}

.mt_2 {
    margin-top: 2%;
}

.grid-3 {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 10px;
}

.grid-2 {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 10px;
}

.grid-4 {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-gap: 10px;
}

.grid-flex {
    display: flex;
    justify-content: space-between;
}

.form-group {
    margin-top: 20px;
}

.form-flex-2 {
    display: flex;
    grid-gap: 5px;
}

@media screen and (max-width: 720px) {

    body,
    html {
        background: #fff;
        /* border: 2px solid #0E60D8; */
    }

    .app-dashboard .app-dashboard-cover .app-dashboard-nav {
        display: none;
    }

    .app-dashboard .app-dashboard-cover {
        display: block;
    }

    .app-display .app-display-header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 7%;
    }

    .inner-detail .app-display .app-display-header {
        margin-bottom: 10px;
    }

    .app-display .app-display-header h3 {
        font-size: 1.8rem;
    }

    .page-title {
        display: flex;
        align-items: center;
        grid-gap: 10px;
    }

    .page-title svg {
        height: 2.5rem;
    }

    .app-display .app-data-filter ul {
        display: flex;
        flex-wrap: nowrap;
        overflow-x: auto;
        grid-gap: 20px;
        display: grid;
        grid-template-columns: repeat(4, 1fr);
        background: #F2F8F5;
        border-radius: 50px;
        text-align: center;
        padding: 6px 15px;
        align-items: center;
    }

    .app-display .colored-bg {
        background: #010B24;
        padding: 5% 0px;
        padding-bottom: 10%;
    }

    .app-display .app-data-filter ul li {
        font-size: 1.4rem;
        list-style: none;
        color: rgba(0, 0, 0, .7);
    }

    .app-display .app-data-filter ul li.active-filter {
        border: 1px solid #026B41;
        background: #026B41;
        line-height: 1;
        color: #fff;
        border-radius: 50px;
        font-size: 1.3rem;
        padding: 10px;
        padding-bottom: 7px;
    }

    .app-display .app-display-header svg.bell-icon {
        height: 2.5rem;
        transform: rotate(-30deg);
    }

    .app-display .app-data-summary {
        margin-top: 5%;
    }

    .app-display .data-header-block .header-icon-cover,
    .inner-detail .inner-detail-content .header-icon-cover {
        display: flex;
        align-items: center;
        grid-gap: 10px;
    }

    .app-display .data-header-block .header-icon-cover p,
    .inner-detail .inner-detail-content .header-icon-cover .header-icon-box p {
        font-size: 1.1rem;
        margin-bottom: 3px;
    }

    .app-display .data-header-block .header-icon-cover h4,
    .inner-detail .inner-detail-content .header-icon-cover .header-icon-box h4 {
        font-size: 1.5rem;
    }

    .app-display .data-header-block,
    .inner-detail .inner-detail-content .data-header-block {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 0px 10px;
        padding-bottom: 10px;
    }

    .app-display .data-header-block .header-icon-cover .header-icon-box,
    .inner-detail .inner-detail-content .header-icon-cover .header-icon-box {
        background: #E4E4E4;
        height: 60px;
        width: 60px;
        border-radius: 50%;
        border: 1px solid #E4E4E4;
    }

    .app-display .data-header-block h5 {
        font-size: 1.3rem;
        text-align: right;
        margin-bottom: 3px;
    }

    .app-display .data-header-block .delivery-status {
        color: #FF0000;
        font-size: 1.3rem;
        margin-bottom: 0px;
        text-align: right;
    }

    .app-display .data-detail-header {
        border: 1px solid #E4E4E4;
        padding: 15px 0px;
        border-radius: 12px;
        margin-bottom: 15px;
    }

    /* .app-display .data-delivery-details {
        margin-top: 10%;
    } */

    .divider-line {
        border-bottom: 1px solid #E4E4E4;
        width: 100%;
        margin: 20px 0px;
    }

    .app-display .receiver-detail {
        padding: 0px 10px;
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    .app-display .receiver-detail p {
        font-size: 1.3rem;
        margin-bottom: 0px;
    }

    .app-display .receiver-detail h5 {
        font-size: 1.3rem;
        margin-bottom: 10px;
    }

    .app-display .receiver-detail a {
        display: flex;
        align-items: center;
        grid-gap: 5px;
        color: #006B5D;
        text-decoration: unset;
        font-size: 1.3rem;
    }

    .app-display .receiver-detail button,
    .inner-detail.disputes button {
        border: 1px solid #E4E4E4;
        padding: 8px 25px;
        background: transparent;
        border-radius: 50px;
        font-size: 1.3rem;
    }

    .items-list .divider-line {
        margin: 10px 0px;
    }

    .inner-detail.disputes button {
        width: 100%;
    }

    .app-display .receiver-detail svg {
        height: 1.2rem;
        fill: #006B5D;
    }

    .footer {
        box-shadow: rgba(0, 0, 0, .3) 0px -6px 6px -6px;
        display: flex;
        justify-content: space-between;
        text-align: center;
        position: fixed;
        width: 100%;
        bottom: 0px;
        padding: 20px 10px;
        padding-bottom: 15px;
        background: #fff;
        z-index: 100;
    }

    .footer svg {
        height: 2rem;
        margin-bottom: 5px;
        stroke: rgba(0, 0, 0, .3);
    }

    .footer h4 {
        margin-bottom: 0px;
        font-size: 1.3rem;
        line-height: 1.6;
        color: rgba(0, 0, 0, .3);
    }

    .footer .active-footer h4,
    .footer .active-footer svg {
        color: #006B5D;
        stroke: #006B5D;
    }

    .footer a {
        text-decoration: none;
    }


    .app-display .app-data-summary .summary-box {
        background: #0E60D8;
        border-radius: 8px;
        padding: 20px 15px;
    }

    .app-display .app-data-summary .grid-2 {
        grid-gap: 5px;
        row-gap: 10px;
    }

    .app-display .app-data-summary .summary-box._2 {
        background: #7C19E6;
    }

    .app-display .app-data-summary .summary-box._3 {
        background: #292929;
    }

    .app-display .app-data-summary .summary-box._4 {
        background: #753FF6;
    }

    .app-display .app-data-summary .summary-box p {
        color: rgba(253, 253, 253, .8);
        font-size: 1.4rem;
        font-family: 'Gilroy Medium';
    }

    .app-display .app-data-summary .summary-box h4 {
        color: #fff;
        font-size: 1.8rem;
        margin-bottom: 5px;
    }

    .app-dashboard-cover .app-dashboard-summary .summary-box p.btn {
        font-size: 1rem;
        line-height: 1;
    }

    .app-data-summary .app-data-filter {}

    .app-display .map-box {
        height: 400px;
        width: 100%;
        background: #E4E4E4;
    }

    /* .inner-detail {
        padding: 3% 0px;
    } */

    .inner-detail .inner-detail-content {
        /* background: #fff;
        background: red;
        height: 400px;
        border-radius: 64px 64px 0px 0px; */
    }

    /* .inner-detail .app-display-header {
        background: #fff;
        flex-direction: column;
        padding: 10px;
        align-items: unset;
        margin-bottom: 0px;
    } */

    .inner-detail .app-display-header svg {
        height: 30px;
    }

    .inner-detail .inner-info-content .summary-box {
        border: 1px solid #E4E4E4;
        border-radius: 12px;
        padding: 15px;
    }

    .inner-detail .inner-info-content .summary-box svg {
        height: 1.6rem;
        margin-bottom: 5px;
    }

    .inner-detail .inner-info-content .summary-box p {
        margin-bottom: 5px;
        font-size: 1.3rem;
    }

    .inner-detail .inner-info-content .summary-box h4 {
        /* margin-bottom: 5px; */
        font-size: 1.4rem;
    }

    .inner-tile {
        font-size: 1.4rem;
        margin-bottom: 5px;
    }

    .inner-detail .extra-info-content {
        background: #F8FAFC;
        padding: 5% 0px;
        margin-top: 8%;
    }

    .inner-detail.disputes .extra-info-content {
        margin-top: unset;
        background: unset;
    }

    .inner-detail.disputes .dispute-author.grid-flex {
        padding: 10px;
        align-items: center;
    }

    .inner-detail.disputes .item-name h4 {
        font-size: 1.5rem;
        margin-bottom: 10px;
    }

    .inner-detail.disputes .grid-flex p {
        font-size: 1.3rem;
        margin-bottom: 0px;
    }

    .inner-detail.disputes .grid-flex {
        align-items: center;
        margin-bottom: 10px;
    }

    .inner-detail.disputes .dispute-author.grid-flex h3 {
        font-size: 1.3rem;
        margin-bottom: 5px;
    }

    .inner-detail.disputes .dispute-author.grid-flex h4 {
        font-size: 1.3rem;
    }

    .inner-detail.disputes .dispute-author.grid-flex p {
        font-size: 1.3rem;
    }

    .inner-detail.disputes .items-list .img-cover {
        background: #E4E4E4;
    }


    .inner-detail .ant-tabs-nav .ant-tabs-tab-btn {
        font-family: 'Gilroy Medium';
        font-size: 1.3rem !important;
    }

    .inner-detail .ant-tabs-tab {
        background: transparent !important;
    }

    .inner-detail .ant-tabs-tab.ant-tabs-tab-active {
        background: #fff !important;
    }

    .inner-detail .items-list .img-cover {
        background: #fff;
        height: 100px;
        width: 100px;
        border-radius: 12px;
    }

    .inner-detail .items-list .items-name-detail {
        display: flex;
        align-items: center;
        grid-gap: 10px;
        width: 100%;
        padding: 10px;
    }

    .inner-detail .items-list .item-name {
        width: 100%;
    }

    .inner-detail .items-list .items-extra-detail {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-gap: 10px;
    }

    .inner-detail .item-single-list {
        border: 1px solid #E4E4E4;
        border-radius: 12px;
        margin-bottom: 10px;
    }

    .inner-detail .item-single-list .items-extra-detail {
        padding: 10px;
    }

    .inner-detail .divider-line {
        margin: 0px;
        margin-bottom: 15px;
    }

    .inner-detail .extra-info-content button {
        padding: 8px 25px;
        width: 100%;
        display: block;
        background: transparent;
        border-radius: 50px;
        border: 1px solid #E9E9EA;
    }

    .inner-detail .extra-info-content button.btn-green {
        background: #10AA58;
        border: 1px solid #10AA58;
        margin-bottom: 20px;
    }

    .inner-detail .extra-info-content button.btn-border-green {
        background: transparent;
        border: 1px solid #10AA58;
    }

    .inner-detail .extra-info-content .grid-2,
    .inner-detail .extra-info-content .address {
        margin-bottom: 10px;
        grid-gap: 20px;
    }

    .inner-detail .extra-info-content .action-button {
        margin-top: 30px;
        margin-bottom: 20%;
    }

    .inner-detail .delivery-status {
        background: #FCB500;
        padding: 4px 20px;
        font-size: 1.2rem;
        line-height: 1.8;
        border-radius: 50px;
        color: #111 !important;
    }

    .app-data.inner-detail .priority-btn {
        width: 100%;
        border-radius: 50px;
        padding: 13px 25px;
        margin-top: 5%;
    }

    .app-data {
        position: relative;
    }

    .extra-profile-cover {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        grid-gap: 10px;
        border-radius: 3px;
    }

    .extra-profile-cover .extra-profile-link {
        background: #E4E4E4;
        padding: 20px;
        text-align: center;
        border-radius: 8px;
    }

    .extra-profile-cover svg {
        height: 2rem;
        margin-bottom: 10px;
    }

    .banner-design {
        background: #E5E7EB;
        height: 15rem;
        border-radius: 12px;
        width: 100%;
        display: block;
    }

    .setings-links ul li {
        margin-bottom: 30px;
        list-style: none;
    }

    .setings-links ul li a {
        font-size: 1.4rem;
        text-decoration: none;
        color: #292929;
        align-items: center;
        grid-gap: 10px;
        display: flex;
    }

    .setings-links ul li a svg {
        height: 2rem;
    }

    .driver-settings-name {
        display: grid;
        grid-template-columns: 3fr 1fr;
        align-items: center;
        margin-top: 5%;
    }

    .driver-settings-name p {
        margin-bottom: 0px;
        font-size: 1.2rem;
    }

    .driver-settings-name h2 {
        font-size: 1.8rem;
        margin-bottom: 5px;
    }

    .blank-avatar {
        background: #E4E4E4;
        height: 80px;
        width: 80px;
        border-radius: 50%;
    }

    .priorities-modal button {
        display: block;
        padding: 12px;
        width: 100%;
    }

    .dashboard .driver-settings-name {
        display: flex;
        align-items: center;
        margin-top: 0%;
        grid-gap: 10px;
    }

    .dashboard .driver-settings-name p {
        margin-bottom: 0px;
        font-size: 1.2rem;
        color: rgba(253, 253, 253, .8);
    }

    .dashboard .driver-settings-name h2 {
        font-size: 1.6rem;
        margin-bottom: 5px;
        color: #fff;
    }

    .dashboard .blank-avatar {
        background: #FCC2AB;
        height: 70px;
        width: 70px;
        border-radius: 50%;
    }

    /* FCC2AB */
    .dashboard-banner {
        height: 15rem;
        background: #0D41E2;
        border-radius: 12px;
    }

    .dashboard-summary .icon-cover {
        background: #242422;
        height: 50px;
        width: 50px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 50%;
        margin: 0px auto;
        margin-bottom: 10px;
    }

    .dashboard-summary .icon-cover svg {
        height: 1.6rem;
        width: auto;
        color: #F1B354;
        fill: #533C10;
    }

    .dashboard-summary h4 {
        color: #fff;
        font-size: 1.3rem;
    }

    .dashboard-summary ._2 .icon-cover {
        background: #022741;
    }

    .dashboard-summary ._3 .icon-cover {
        background: #15193E;
    }

    .dashboard-summary ._4 .icon-cover {
        background: #0E1D32;
    }

    .dashboard-summary ._2 .icon-cover svg {
        color: #1EC4E3;
        fill: #024974;
    }

    .dashboard-summary ._3 .icon-cover svg {
        color: #A281E0;
        fill: #352758;
    }

    .dashboard-summary ._4 .icon-cover svg {
        color: #8BA9A6;
        fill: #243843;
    }

    .dashboard-summary .grid-4 {
        text-align: center;
        margin-top: 5%;
        grid-gap: 10px;
    }

    .brown-bg {
        background: #E4E4E4;
        padding: 5% 0px;
        padding-top: 10px;
    }

    .btn-black.deliveries {
        width: 100%;
        padding: 15px;
        font-size: 1.4rem;
        margin-top: 10%;
    }

    .app-data.inner-detail .header-icon-cover p {
        font-size: 1.3rem;
    }

    .app-data.inner-detail .header-icon-cover h4 {
        font-size: 1.6rem;
    }

    .inner-detail.disputes .inner-title .active {
        border-bottom: 2px solid #006B5D;
    }
    
    .inner-detail.disputes .inner-title h2 {
        font-size: 1.6rem;
    }

    .inner-detail.disputes .inner-title .title-list {
        padding-bottom: 10px;
    }

    .inner-detail.disputes .inner-title {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        text-align: center;
        margin-bottom: 5%;
    }

    .dispute-detail li {
        display: flex;
        justify-content: space-between;
    }

    .dispute-detail li span:last-child {
        text-align: right;
    }

    .top-bar.fixed {
        position: fixed;
        top: 0px;
        background: #fff;
        background: #E4E4E4;
        width: 100%;
        z-index: 10;
        height: max-content;
        padding-bottom: 10px;
        padding-top: 1.5%;
        box-shadow: 0px 4px 10px rgba(0, 0, 0, .2);
    }

    .top-bar.fixed.mt_1 {
        margin-top: 0px;
    }


    .product-detail .top-bar {
        position: fixed;
        top: 0px;
        background: transparent;
        width: 100%;
        padding-top: 10px;
        padding-bottom: 10px;
        display: flex;
        justify-content: space-between;
    }

    .product-detail .top-bar.fixed {
        background: #fff;
    }
}

.top-welcome-address {
    display: flex;
    align-items: center;
}

.signin-page img.logo {
    height: 2.5rem;
}


/* drawer */
.ant-drawer-body {
    padding: 0px !important;
}
.modal-header{
    padding: 15px 1.5%;
    border-bottom: 2px solid #dddcdc;
}

.ant-drawer-body .contain {
    padding: 2% 1.5%;
}